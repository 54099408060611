import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GenerateLink } from "../utils/generateLinks"

export let Menu = () => {
  const { sanityMenu } = useStaticQuery(graphql`
    query GetMenu {
      sanityMenu {
        item {
          label
          _key
          childpages {
            slug {
              current
              _type
            }
          }
        }
      }
    }
  `)

  return (
    <>
      {sanityMenu.item.map(({ label, childpages, _key }) => (
        <GenerateLink theme={"dark"} link={childpages[0].slug} key={_key}>
          {label}
        </GenerateLink>
      ))}
    </>
  )
}
