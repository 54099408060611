import React from "react"

import tw, { styled } from "twin.macro"
import { Link } from "gatsby"

let Button = styled(Link)`
  ${tw`font-sans uppercase py-4 px-8 cursor-pointer inline-block relative mt-6 items-end self-end`};
  ${p =>
    p.theme === "dark" ? tw`bg-white text-primary` : tw`bg-primary text-white`};
`
let ExternalButton = styled.a`
  ${tw`text-primary font-sans uppercase py-4 px-8 cursor-pointer inline-block relative mt-6`};
  ${p =>
    p.theme === "dark" ? tw`bg-white text-primary` : tw`bg-primary text-white`};
`

let InternalLink = styled(Link)`
  ${tw`font-sans uppercase py-4 px-8 cursor-pointer inline-block relative text-center md:text-left`};
  ${p =>
    p.theme === "dark"
      ? tw`text-white border-b-2 border-white border-opacity-25 md:border-none`
      : tw`text-primary`}
  ${p => p.NoPadding && tw`md:p-0 mb-2`};
`

let ExternalLink = styled.a`
  ${tw`font-sans uppercase py-4 px-8 cursor-pointer inline-block relative`};
  ${p => (p.theme === "dark" ? tw`text-white` : tw`text-primary`)}
  ${p => p.NoPadding && tw`p-0 mb-2`};
`

export const GenerateButton = ({ children, link, theme }) => {
  if (link[0] === "/") {
    return (
      <Button to={link} theme={theme}>
        {children}
      </Button>
    )
  } else {
    return (
      <ExternalButton href={link} theme={theme}>
        {children}
      </ExternalButton>
    )
  }
}

export const GenerateLink = ({ children, link, theme, NoPadding }) => {
  if (link._type === "slug") {
    return (
      <InternalLink
        NoPadding={NoPadding}
        to={`${link.current !== "/" ? `/${link.current}` : `/`}`}
        theme={theme}
      >
        {children}
      </InternalLink>
    )
  } else {
    return (
      <ExternalLink href={link} theme={theme}>
        {children}
      </ExternalLink>
    )
  }
}
