import { useEffect, useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import tw, { styled } from "twin.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars, faTimes } from "@fortawesome/pro-solid-svg-icons"
import { Menu } from "./Menu"
import { motion } from "framer-motion"
import { useWindowSize } from "../hooks/useWindowSize"

const NavWrapper = tw.nav`
bg-white w-full lg:relative z-50
`

const Image = tw.img`
  w-24
`

const NavContainer = styled.section`
  ${tw`flex-col lg:flex-row container flex justify-between py-6`};
`
const MenuContainer = styled(motion.section)`
  ${tw`flex-col lg:flex-row lg:flex justify-center hidden fixed inset-0 lg:inset-auto z-40 bg-primary lg:relative py-6`};
`

const MenuWrapper = styled.section`
  ${tw`bg-primary text-white hidden lg:visible lg:block`};
  ${p => p.menuOpen && tw`visible block`};
`

const MenuButton = styled.div`
  ${tw`lg:hidden font-sans inline-block mx-auto lg:relative z-50 mr-6 cursor-pointer`};
  ${p => (p.menuOpen ? tw`text-white fixed` : tw`text-primary`)};

  @media screen and (max-width: 1024px) {
    right: 5%;
    top: 3%;
  }
`

const MenuAnimation = {
  visible: {
    transform: "translateX(0px)",
    opacity: 1,
    display: "flex",
    height: "auto",
  },
  hidden: {
    transform: "translateX(200px)",
    opacity: 0,
    display: "none",
    height: "auto",
  },
  exit: { transform: "translateX(200px)", opacity: 0, display: "none" },
}

const MenuButtonWrapper = tw.div`
  flex
`

const LogoArea = tw.div`
  flex justify-between items-center
`

const Header = ({ siteTitle }) => {
  const { sanityCompany } = useStaticQuery(graphql`
    query Logo {
      sanityCompany {
        phone
        email
        logo {
          asset {
            url
          }
        }
      }
    }
  `)

  const [showMenu, toggleShowMenu] = useState(false)
  const [mobile, toggleMobile] = useState(false)

  const WindowSize = useWindowSize()

  useEffect(() => {
    if (WindowSize.width > 1024) {
      toggleMobile(false)
    } else {
      toggleMobile(true)
    }
  }, [WindowSize])

  const { logo } = sanityCompany

  return (
    <NavWrapper>
      <NavContainer>
        <LogoArea>
          <Link to={"/"}>
            <Image src={logo.asset.url} />
          </Link>
          <MenuButtonWrapper>
            <MenuButton
              menuOpen={showMenu}
              onClick={() => toggleShowMenu(!showMenu)}
            >
              <FontAwesomeIcon
                icon={!showMenu ? faBars : faTimes}
                size={"2x"}
              />
            </MenuButton>
          </MenuButtonWrapper>
        </LogoArea>
      </NavContainer>
      <MenuWrapper menuOpen={showMenu}>
        <MenuContainer
          animate={mobile ? (!showMenu ? "hidden" : "visible") : "visible"}
          variants={MenuAnimation}
          transition={{ ease: "easeInOut", duration: 0.3 }}
          initial={mobile ? "hidden" : "visible"}
        >
          <Menu />
        </MenuContainer>
      </MenuWrapper>
    </NavWrapper>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
